import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import SliceZone from "../components/common/SliceZone";
import { constructActiveDoc } from "../helpers";

const Homepage = ({ data }) => {
  if (!data) return null;
  const { settings, page } = data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <SliceZone slices={page.data.body} settings={settings} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      ...prismicHomepageFragment
    }
  }
`;

export default withPrismicPreview(Homepage);
